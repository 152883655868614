.dschutz1 {
    font-size: 15px;
    text-align: block;
    background-color: rgb(229, 229, 229);
    color: #000000;
    height: 100%;
    width:  80%;
    padding-left: 30px;
    padding-right: 40px;
    position: relative;
} 

.dschutz1 > p {
    font-family: "Trebuchet MS";
}

.tablet {
    border: solid 0px;
}

.tabled {
    font-family: "Trebuchet MS";
    border: solid 0px;
    
}

.titled {
   font-weight:bold;
}
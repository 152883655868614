* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .Home,
  .Teams,
  .Shop,
  .Canteen,
  .Verein,
  .News,
  .FAQ,
  .Gameday,
  .Datenschutzerklärung,
  .Geschichte,  
  .U18,
  .U16,
  .Kampfmannschaft,
  .sign-up,
  .Vorstand {
    display: flex;
    justify-content: center;
  }
  
  .Verein {
    background-image: url('/public/images/image1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  
  .Shop {
    background-image: url('/public/images/image1.png');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


  .Teams {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


  .FAQ {
    background-image: url('/public/images/image1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


  .Canteen {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


  .News {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


  .Home {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  } 


  .Gameday {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  

  .Datenschutzerklärung {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  
  .Geschichte {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .Geschichte {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .Geschichte {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .Geschichte {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .U18 {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .U16 {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  .Kampfmannschaft {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }
  .Kampfmannschaftbild {
    
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  .Vorstand {
    background-image: url('/public/images/image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }
  .FAQimage {
    
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 20%;
    
  }
  .FAQtextquestion {
    
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.FAQtextanswer {
    
  font-size: 20px;
  font-weight:normal;
  text-align: center;
}
  .Jugendmannschaftbild {
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .Mannschaften{
     
    text-align: center;
    padding-top: 2%; 
    
  }
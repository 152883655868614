.titled{
    text-align: center;
}

table {
    text-align:center;
    border: solid 1px;
    width: 100%;
}

th {
    width: 100px;
    border:solid 1px;
    background-color: rgb(25, 25, 55);
    color:white;
    font-weight: bolder;
}

td {
    border:solid 1px;
}

